import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  AOS.init({
    // disable: function() {
    //   var maxWidth = 767;
    //   return window.innerWidth < maxWidth;
    // },
    once: true,
    anchorPlacement: 'center-bottom',
  });

  // index contact popup
  $('#contactPopup').fadeIn(300);
  $('#contactPopup').find('.contact-popup-close').on('click', function() {
    $('#contactPopup').fadeOut(300);
  });

  // transparent header
  function headerScroll() {
    var currentPage = window.location.pathname;
    var targetElement = $('[data-routes-name="pages-index"]');
    if (targetElement.length) {
      var target = $('#indexMenuOffset').offset().top;
      if (currentPage === '/' || currentPage === '/en') {
        if ($(window).scrollTop() >= target) {
          $('.header').addClass("index-header-ani");
        } else {
          $('.header').removeClass("index-header-ani");
        }
      }
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
    $('.header-mobile-bg').toggleClass('active');
  });

  $('.menu-link').click(function(e) {
    $(this).parent().toggleClass('active').siblings().removeClass('active current');
  });

  // index indexBanner
  var indexBanner = new Swiper('#indexBanner', {
    autoplay: {
      delay: 5000,
    },
    loop: true,
    speed: 0,
    slidesPerView: 1,
  });

  // cate search
  $('#cateSearchBtn').on('click', function() {
    $(this).toggleClass('active');
    $(this).siblings('#cateSearchDropdown').slideToggle(200);
  });
  $('.cate-item').on('click', function() {
    $(this).parent('#cateSearchDropdown').slideToggle(200);
  });

  $(document).click(function(event) {
    if(!$(event.target).closest('.cate-search').length) {
      if($('#cateSearchDropdown').is(":visible")) {
        $("#cateSearchDropdown").slideUp(200);
        $('#cateSearchBtn').removeClass('active');
      }
    }
  });

  // select 空值的時候樣式不同
  function updateSelectColor() {
    let select = $('select.form-control');
    let value = select.val();
    if (value == '') {
      select.addClass('prompt-option');
    } else {
      select.removeClass('prompt-option');
    }
  }
  updateSelectColor();

  $('select.form-control').on('change', function () {
    let select = $(this);
    let value = $(this).val();
    if (value == '') {
      select.addClass('prompt-option');
    } else {
      select.removeClass('prompt-option');
    }
  });

  // product inner
  var productThumbnail = new Swiper('#productThumbnail', {
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
  });
  var productShow = new Swiper('#productSlider', {
    thumbs: {
      swiper: productThumbnail,
    },
  });

  //form input
  document.querySelectorAll('input, select, textarea').forEach(input => {
    input.addEventListener('input', () => {
      if (input.value.length >= 0) {
        input.classList.add('focused')
        input.labels.forEach(label => {
          label.classList.add('focused')
        })
      };
    });

    input.addEventListener('focus', () => {
      input.classList.add('focused')
      input.labels.forEach(label => {
        label.classList.add('focused')
      })
    });

    input.addEventListener('blur', () => {
      if (input.value.length <= 0) {
        input.classList.remove('focused')
        input.labels.forEach(label => {
          label.classList.remove('focused')
        })
      };
    });
  });
});

