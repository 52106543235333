import "@fontsource/inter/200.css"
import "@fontsource/inter/300.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/700.css"
import Cookies from 'js-cookie';
import 'core-js';
import 'public/main'
import 'public/style.scss'
import ahoy from "ahoy.js"
import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;

